/* src/components/PrivacyPolicy.css */
.privacy-policy {
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .privacy-policy h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .privacy-policy h2 {
    font-size: 1.75rem;
    margin-top: 1.5rem;
  }
  
  .privacy-policy p {
    font-size: 1rem;
    color: #555;
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  