.bg-black-gray {
    background-color: #e2e1e1; /* Adjust this color as needed to get the desired black gray shade */
    color: rgb(5, 0, 0);
    /* Optional: Ensures text is readable against the dark background */
  
  }

  .reduced-container {
    padding: 2rem; /* Adjust padding as needed */
    width: 50%;    /* Adjust width as needed */
    margin: auto;  /* Center the container */
    background-color: #858585; /* Ensure the background color is set properly */
  }

  .link-sndarecoy{
    color: black;
  

  }
  