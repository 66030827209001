/* Service.css */
.card {
    transition: transform 0.2s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  /* Service.css */
.team-title {
  text-align: center;
  color: white;
}
